'use client';

import { type PropsWithChildren, useMemo } from 'react';

import {
  StatsigClient,
  type StatsigOptions,
  StatsigUser,
} from '@statsig/js-client';
import { StatsigProvider } from '@statsig/react-bindings';

type Props = PropsWithChildren & {
  readonly clientSdkKey: string;
  readonly user: StatsigUser;
  readonly values: string;
};

const isProduction = process.env.NEXT_PUBLIC_VERCEL_ENV === 'production';
const isPreview = process.env.NEXT_PUBLIC_VERCEL_ENV === 'preview';

export default function BootstrappedStatsigProvider({
  clientSdkKey,
  user,
  values,
  children,
}: Props): JSX.Element {
  const client = useMemo(() => {
    const options: StatsigOptions = {
      // networkConfig: {
      //   api: '/api/statsig/', // Your Next.js server
      // },
      environment: {
        tier: isProduction
          ? 'production'
          : isPreview
            ? 'staging'
            : 'development',
      },
      // disableStatsigEncoding: true,
    };

    const client = new StatsigClient(clientSdkKey, user, options);
    client.dataAdapter.setData(values);

    client.initializeSync();
    return client;
  }, [clientSdkKey, user, values]);

  return <StatsigProvider client={client}>{children}</StatsigProvider>;
}
