'use client';

import { useLiveMode } from '@sanity/react-loader';
import { VisualEditing } from 'next-sanity';
import { useEffect } from 'react';

import { client } from '@/sanity/lib/client';

// Always enable stega in Live Mode
const stegaClient = client.withConfig({ stega: true });

export default function LiveVisualEditing() {
  useLiveMode({ client: stegaClient });
  useEffect(() => {
    //if not in iframe go to  location.href = '/api/disable-draft';
    if (window === window.parent) {
      location.href = '/api/disable-draft';
    }
  }, []);

  return <VisualEditing />;
}
