import { ClientPerspective } from 'next-sanity';
import { dataset, projectId, apiVersion } from './client.env';
const isProduction =
  process.env.NEXT_PUBLIC_VERCEL_ENV === 'production' || false;
const config = {
  projectId: projectId,
  dataset: dataset,
  apiVersion: apiVersion,
  useCdn: isProduction,
  perspective: 'published' as ClientPerspective,
  stega: {
    enabled: false,
    studioUrl: '/admin',
    filter: (props: any) => {
      const { sourcePath } = props;

      if (sourcePath.includes('displayOptions')) {
        return false;
      }
      // // The default behavior is packaged into `filterDefault`, allowing you to enable encoding fields that are skipped by default
      return props.filterDefault(props);
    },
  },
};

export default config;
