'use client';
import { datadogLogs } from '@datadog/browser-logs';
const isProduction = process.env.NEXT_PUBLIC_VERCEL_ENV === 'production';

const clientToken = assertValue(
  process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
  'Missing environment variable: NEXT_PUBLIC_DATADOG_CLIENT_TOKEN'
);

// const version = assertValue(
//   process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
//   'Missing environment variable: NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA'
// );

const version = '1';

const site = assertValue(
  process.env.NEXT_PUBLIC_DATADOG_SITE,
  'Missing environment variable: NEXT_PUBLIC_DATADOG_SITE'
);

datadogLogs.init({
  clientToken: clientToken,
  site: site,
  forwardErrorsToLogs: true,
  sessionSampleRate: 100,
  trackingConsent: 'granted',
  service: 'www.ikhokha.com',
  version: version,
  env: isProduction ? 'prod' : 'preview',
  forwardConsoleLogs: ['debug'],
});

export default function DatadogLogsInit() {
  // Render nothing - this component is only included so that the init code
  // above will run client-side
  return null;
}

function assertValue<T>(v: T | undefined, errorMessage: string): T {
  if (v === undefined) {
    throw new Error(errorMessage);
  }

  return v;
}
