'use client';
import React from 'react';
import { Code } from '@phosphor-icons/react';

export default function ShowEnvFlag() {
  return (
    <>
      <div className='pointer-events-none fixed left-0 top-0 z-[99999] h-full w-full border-4 border-orange opacity-50'>
        <div className='absolute bottom-0 left-0 flex w-full justify-end'>
          <div className='relative flex items-center gap-1 rounded-tl-md bg-orange px-2 py-0.5 text-xs text-white'>
            <span>
              <Code className='text-sm' />
            </span>
            <span style={{ fontFamily: 'Roboto, Inter, sans-serif' }}>
              Staging Dataset
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
